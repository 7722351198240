import React from 'react';

import Layout from 'src/components/layout/layout';
import Login from 'src/components/login/login';
import LoginLTA from 'src/components/login/login-lta';

const isLTA = process.env.GATSBY_CLIENT === 'LTA';

const LoginPage: React.FC = () => {
  return (
    <Layout nav={false}>
      {isLTA && <LoginLTA />}
      {!isLTA && <Login />}
    </Layout>
  );
};

export default LoginPage;
